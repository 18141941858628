.-hidden {
    display: none !important;
}

@include breakpoint(xsmd) {
    .-xsmd {
        &-hidden {
            display: none !important;
        }
    }
}

@include breakpoint(md) {
    .-md {
        &-hidden {
            display: none !important;
        }
    }
}

.-visuallyHidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.-mobileHidden{
    display: none; 
}

@include breakpoint(md) {
    .-mobileHidden{
        display: initial; 
    }
}

.-desktopHidden{
    display: initial; 
}

@include breakpoint(md) {
    .-desktopHidden{
        display: none; 
    }
}