.-gridLayout {
    $-spacing: -PxToRem(map-get($-h-spacing, 'sm') / 2);
    $-spacing-md: -PxToRem(map-get($-h-spacing, 'md') / 2);

    display: block;
    overflow: hidden;

    &[data-overflowVisible="true"] {
        overflow: visible;
    }

    &>* {
        display: flex;
        flex-wrap: wrap;
        // align-items: flex-start;
    }

    &>*>* {
        display: flex;
    }

    &[data-noWrap="true"]>* {
        flex-wrap: nowrap;
    }

    &[data-align="center"]>* {
        align-items: center;
    }

    &[data-align="end"]>* {
        align-items: flex-end;
    }

    &[data-justify="center"]>* {
        justify-content: center;
    }

    &[data-reverseDirection-md="true"]>* {
        @include breakpoint(md) {
            flex-direction: row-reverse;
        }
    }

    &[data-equalizeContentsHeight="true"]>*>* {
        display: flex;
        flex-direction: column;

        >* {
            flex-grow: 1;
        }
    }

    @each $name,
    $value in $-fragment {
        &>*>[data-fragment="#{$name}"] {
            width: $value*100%;
        }

        &>*>[data-fragment-sm="#{$name}"] {
            @include breakpoint(sm) {
                width: $value*100%;
            }
        }

        &>*>[data-fragment-md="#{$name}"] {
            @include breakpoint(md) {
                width: $value*100%;
            }
        }
    }

    &[data-contentAlign="stretch"]>*>*>* {
        height: 100%;
    }

    &[data-fragment-sm] {
        &:not([data-fragment])>*>* {
            @include breakpoint(xssm) {
                flex-grow: 1;
                width: 100%;
            }
        }
    }

    &[data-fragment-md] {
        &:not([data-fragment]):not([data-fragment-sm])>*>* {
            @include breakpoint(xsmd) {
                flex-grow: 1;
                width: 100%;
            }
        }
    }

    @each $name,
    $value in $-fragment {
        &[data-fragment="#{$name}"] {
            &>*>* {
                width: $value*100%;
            }
        }

        &[data-fragment-sm="#{$name}"] {
            &>*>* {
                @include breakpoint(sm) {
                    width: $value*100%;
                }
            }
        }

        &[data-fragment-md="#{$name}"] {
            &>*>* {
                @include breakpoint(md) {
                    width: $value*100%;
                }
            }
        }

        &[data-fragment-lg="#{$name}"] {
            &>*>* {
                @include breakpoint(lg) {
                    width: $value*100%;
                }
            }
        }
    }

    &[data-fragmentMinWidth] {
        flex-grow: 1;
    }

    @each $name,
    $value in ('05': 3, '04': 4, '03': 5, '02': 6, '01': 8, '1': 10, '2': 12, '3': 16, '4' : 20, '5': 24) {
        &[data-fragmentMinWidth="#{$name}"] {
            &>*>* {
                min-width: $value*1ch;
            }
        }
    }

    @each $name,
    $value in $-scale {
        &[data-space="#{$name}"] {
            &>* {
                margin: $-spacing*$value*-1;

                @include breakpoint(md) {
                    margin: $-spacing-md*$value*-1;
                }

                &>* {
                    padding: $-spacing*$value;

                    @include breakpoint(md) {
                        padding: $-spacing-md*$value;
                    }
                }
            }
        }
    }
}