$-spacing: -PxToRem(map-get($-h-spacing, 'sm') / 2);
$-spacing-md: -PxToRem(map-get($-h-spacing, 'md') / 2);
$-column-width: -PxToRem($-grid-column-width);
$-relative-spacing: -PxToEm(map-get($-h-spacing, 'sm') / 2, $-base-font-size);
$-relative-spacing-md: -PxToEm($-grid-gutter-width);
$-relative-column-width: -PxToEm($-grid-column-width);


.-width {
    @for $i from 1 through $-grid-column-count {
        &-colspan#{$i} {
            width: $-relative-column-width*$i + $-relative-spacing*($i - 1);

            @include breakpoint(md) {
                width: $-relative-column-width*$i + $-relative-spacing-md*($i - 1);
            }
        }
    }

    &Zero {
        width: 0;
    }

    &Stratch {
        width: 100%;
    }
}