.-sidebarLayout {
    $-spacing: -PxToRem(map-get($-h-spacing, 'sm') / 2);
    $-spacing-md: -PxToRem(map-get($-h-spacing, 'md') / 2);
    $-column-width: -PxToRem($-grid-column-width);
    $-relative-spacing: -PxToEm(map-get($-h-spacing, 'sm') / 2, $-base-font-size);
    $-relative-spacing-md: -PxToEm(map-get($-h-spacing, 'md') / 2, $-base-font-size-md);
    $-relative-column-width: -PxToEm($-grid-column-width, $-base-font-size-md);

    display: block;
    overflow: hidden;
    
    &[data-overflowVisible="true"] {
        overflow: visible;
    }

    & > * {
        display: flex;
        flex-wrap: wrap;
    }

    & > * > * {
        flex-grow: 1;
    }

    &[data-noWrap="true"] > * {
        flex-wrap: nowrap;
    }

    &[data-align="end"] > * {
        align-items: flex-end;
    }

    &[data-align="center"] > * {
        align-items: center;
    }

    &[data-contentAlign="stretch"] > * > * > * {
        height: 100%;
    }

    &[data-spaceVertical="false"] > * {
        margin-top: 0 !important;
        margin-bottom: 0 !important;

        & > * {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
        }
    }

    @each $name, $value in $-scale {
        &[data-space="#{$name}"] {
            & > * {
                margin: $-relative-spacing*$value*-1;

                @include breakpoint(md) {
                    margin: $-relative-spacing-md*$value*-1;
                }

                & > * {
                    margin: $-relative-spacing*$value;

                    @include breakpoint(md) {
                        margin: $-relative-spacing-md*$value;
                    }
                }
            }

            &:not([data-sideRight="true"]) > * > :last-child {
                min-width: calc(50% - #{$-relative-spacing*2*$value});

                @include breakpoint(md) {
                    min-width: calc(50% - #{$-relative-spacing-md*2*$value});
                }
            }

            &[data-sideRight="true"] > * > :first-child {
                min-width: calc(50% - #{$-relative-spacing*2*$value});

                @include breakpoint(md) {
                    min-width: calc(50% - #{$-relative-spacing-md*2*$value});
                }
            }
        }
    }

    @each $name, $value in $-scale {
        &[data-space-xsmd="#{$name}"] {
            & > * {
                @include breakpoint(xsmd) {
                    margin: $-relative-spacing*$value*-1;
                }

                & > * {
                    @include breakpoint(xsmd) {
                        margin: $-relative-spacing*$value;
                    }
                }
            }

            &:not([data-sideRight="true"]) > * > :last-child {
                @include breakpoint(xsmd) {
                    min-width: calc(50% - #{$-relative-spacing*2*$value});
                }
            }

            &[data-sideRight="true"] > * > :first-child {
                @include breakpoint(xsmd) {
                    min-width: calc(50% - #{$-relative-spacing*2*$value});
                }
            }
        }
    }

    @for $i from 1 through $-grid-column-count {
        &[data-colspan="#{$i}"] > * > * {
            width: $-relative-column-width*$i + $-relative-spacing*2*($i - 1);

            @include breakpoint(md) {
                width: $-relative-column-width*$i + $-relative-spacing-md*2*($i - 1);
            }
        }
    }

    &:not([data-sideRight="true"]) > * > :last-child, &[data-sideRight="true"] > * > :first-child {
        flex-basis: 0;
        flex-grow: 999999;
        min-width: 50%;
    }

    &[data-switch-xsmd="true"] {
        > * {
            @include breakpoint(md) {
                flex-wrap: nowrap;
            }
        }

        &:not([data-sideRight="true"]) > * > :last-child, &[data-sideRight="true"] > * > :first-child {
            @include breakpoint(xsmd) {
                flex-basis: 100%;
            }
        }
    }
}
