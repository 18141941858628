@mixin font($font-face, $font-face-name, $font-face-prefix, $font-face-suffix, $font-face-filename-separator) {
    $font-face-prefix: if($font-face-prefix != '',$font-face-prefix+$font-face-filename-separator,'');
    $font-face-suffix: if($font-face-suffix != '',$font-face-filename-separator+$font-face-suffix,'');

    @if $font-face and $font-face-name and $font-face-filename-separator {
        $font-face-name-lowercase: to-lower-case($font-face-name);
        $font-face-file-name: -StrRemoveWhitespace($font-face-name-lowercase);
        $font-face-path: "#{$-base-fonts-path}#{$font-face-file-name}/";

        @each $weight-style-name, $weight, $style in $font-face {
            @font-face {
                font-family: $font-face-name;
                src: url('#{$font-face-path}#{$font-face-prefix}#{$font-face-file-name}#{$font-face-filename-separator}#{$weight-style-name}#{$font-face-suffix}.woff2') format('woff2'),
                    url('#{$font-face-path}#{$font-face-prefix}#{$font-face-file-name}#{$font-face-filename-separator}#{$weight-style-name}#{$font-face-suffix}.woff') format('woff');
                font-weight: $weight;
                font-style: $style;
                font-display: swap;
            }
        }
    }
}

@mixin transition-properties($properties, $time:$-transition-time--default, $function:$-transition-function--default) {
    $transition: ();
    $will_change: ();
    @each $property in $properties {
        $transition: append($transition, ($property $time $function), comma);
        $will_change: append($will_change, $property, comma);
    }
    transition: $transition;
    // will-change: $will_change;
}
@mixin expand-absolute {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
@mixin overflow-wrap {
    overflow-wrap: anywhere;
}
// 1.33:1 (4:3) Traditional television & computer monitor standard
// 1.77:1 or 1.78:1 (16:9) HD video std.; U.S. digital broadcast TV std.
// 21:9 (7:3) Approximation of true value 64:27, is a near cinematic movie ratio. Is most commonly seen on higher end monitors, and are commonly called UltraWide monitors.
// 18:9 (2:1) Univisium - It is designed to be a compromise between the cinema 2.39:1 aspect ratio and the HD-TV broadcast 16:9 ratio
// 2.76:1 Ultra Panavision 70
@mixin aspect-ratio-padding($ration:16/9) {
    padding-bottom: 1/$ration*100%;
}

// IE11
@mixin ie11 {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @content;
    }
}

// POINTING DEVICES
// smartphones, touchscreens
@mixin touch-screen {
    @media (hover: none) and (pointer: coarse) {
        @content;
    }
}

// mouse, touch pad
@mixin non-touch-screen {
    @media (hover: hover) and (pointer: fine) {
        @content;
    }
}
