.-clusterLayout {
    $-spacing: -PxToRem(map-get($-h-spacing, 'sm') / 2);
    $-spacing-md: -PxToRem(map-get($-h-spacing, 'md') / 2);
    $-relative-spacing: -PxToEm(map-get($-h-spacing, 'sm') / 2, $-base-font-size);
    $-relative-spacing-md: -PxToEm(map-get($-h-spacing, 'md') / 2, $-base-font-size-md);

    display: block;
    overflow: hidden;
    
    &[data-overflowVisible="true"] {
        overflow: visible;
    }

    & > * {
        display: flex;
        flex-wrap: wrap;
    }

    &[data-noWrap="true"] > * {
        flex-wrap: nowrap;
    }

    &[data-reverseDirection-md="true"] > * {
        @include breakpoint(md) {
            flex-direction: row-reverse;
        }
    }

    &[data-align="end"] > * {
        align-items: flex-end;
    }

    &[data-align="center"] > * {
        align-items: center;
    }

    &[data-justify="end"] > * {
        justify-content: flex-end;
    }

    &[data-justify="center"] > * {
        justify-content: center;
    }

    &[data-justify="space-between"] > * {
        justify-content: space-between;
    }

    @each $name, $value in $-scale {
        &[data-space="#{$name}"] {
            & > * {
                margin: $-relative-spacing*$value*-1;

                @include breakpoint(md) {
                    margin: $-relative-spacing-md*$value*-1;
                }

                & > * {
                    margin: $-relative-spacing*$value;

                    @include breakpoint(md) {
                        margin: $-relative-spacing-md*$value;
                    }
                }
            }
        }
    }
}
