.-switcherLayout {
    $-spacing: -PxToRem(map-get($-h-spacing, 'sm') / 2);
    $-spacing-md: -PxToRem(map-get($-h-spacing, 'md') / 2);
    $-column-width: -PxToRem($-grid-column-width);
    $-relative-spacing: -PxToEm(map-get($-h-spacing, 'sm') / 2, $-base-font-size);
    $-relative-spacing-md: -PxToEm(map-get($-h-spacing, 'md') / 2);
    $-relative-column-width: -PxToEm($-grid-column-width);

    display: block;
    overflow: visible;

    &[data-overflowVisible="false"] {
        overflow: hidden;
    }

    &>* {
        display: flex;
        flex-wrap: wrap;
    }

    &>*>* {
        flex-grow: 1;
        width: 100%;
    }

    &[data-align="center"]>* {
        align-items: center;
    }

    &[data-reverseDirection-md="true"]>* {
        @include breakpoint(md) {
            flex-direction: row-reverse;
        }
    }

    &[data-equalizeContentsHeight="true"]>*>* {
        display: flex;
        flex-direction: column;

        >* {
            flex-grow: 1;
        }
    }

    @for $i from 1 through $-grid-column-count {
        &[data-switchWidth="#{$i}"] {
            >*>* {
                flex-basis: calc((#{$-relative-column-width*$i} - 100%) * 999);
            }
        }
    }

    @each $name,
    $value in $-scale {
        &[data-space="#{$name}"] {
            &>* {
                margin: $-relative-spacing*$value*-1;

                @include breakpoint(md) {
                    margin: $-relative-spacing-md*$value*-1;
                }

                &>* {
                    padding: $-relative-spacing*$value;

                    @include breakpoint(md) {
                        padding: $-relative-spacing-md*$value;
                    }
                }
            }
        }
    }
}