// breakpoint - predefined breakpoints for media query
// usage: @include breakpoint(small) {…}
// possible to pass specific params
// e.g: @include breakpoint(123px, max-height) {…}
@mixin breakpoint($point, $vp-property:min-width) {
    @if type_of($point) != null {
        @if type_of($point) == string {
            @if $point == xssm {
                @media (max-width: -PxToRem($-screen-xs-max)) {
                    @content;
                }
            } @else if $point == sm {
                @media (#{$vp-property}: -PxToRem($-screen-sm-min)) {
                    @content;
                }
            } @else if $point == xsmd {
                @media (max-width: -PxToRem($-screen-sm-max)) {
                    @content;
                }
            } @else if $point == md {
                @media (#{$vp-property}: -PxToRem($-screen-md-min)) {
                    @content;
                }
            } @else if $point == container {
                @media (#{$vp-property}: -PxToRem($-container-width)) {
                    @content;
                }
            } @else if $point == lg {
                @media (#{$vp-property}: -PxToRem($-screen-lg-min)) {
                    @content;
                }
            } @else if $point == xl {
                @media (#{$vp-property}: -PxToRem($-screen-xl-min)) {
                    @content;
                }
            }
        } @else {
            @media (#{$vp-property}: -PxToRem($point)) {
                @content;
            }
        }
    }
}
