.-stackLayout {
    $-spacing: -PxToRem(map-get($-v-spacing, 'sm'));
    $-spacing-md: -PxToRem(map-get($-v-spacing, 'md'));
    $-relative-spacing: -PxToEm(map-get($-v-spacing, 'sm'), $-base-font-size);
    $-relative-spacing-md: -PxToEm(map-get($-v-spacing, 'md'), $-base-font-size-md);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &[data-justify="space-between"] {
        justify-content: space-between;
    }

    &[data-flexbox="false"] {
        display: block;
    }

    & > * {
        margin-top: 0;
        margin-bottom: 0 !important;

        &[data-exclude="stack-layout"] {
            margin-top: 0 !important;
        }
    }

    &[data-spaceCollapse-md="true"] > * + * {
        @include breakpoint(md) {
            margin-top: 0 !important;
        }
    }


    @each $name, $value in $-scale {
        &[data-space="#{$name}"] > * + * {
            margin-top: $-relative-spacing*$value;

            @include breakpoint(md) {
                margin-top: $-relative-spacing-md*$value;
            }
        }
    }

    @each $name, $value in $-scale {
        &[data-space-xsmd="#{$name}"] > * + * {
            @include breakpoint(xsmd) {
                margin-top: $-relative-spacing*$value;
            }
        }
    }
}
