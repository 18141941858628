.-FormControl {

    input[type="color"],
    input[type="date"],
    input[type="datetime-local"],
    input[type="email"],
    input[type="file"],
    input[type="hidden"],
    input[type="image"],
    input[type="month"],
    input[type="number"],
    input[type="password"],
    input[type="radio"],
    input[type="range"],
    input[type="reset"],
    input[type="search"],
    input[type="submit"],
    input[type="tel"],
    input[type="text"],
    input[type="time"],
    input[type="url"],
    input[type="week"],
    textarea,
    select {
        @extend %input-placeholder;
    }

    button {
        @extend %button;
    }

    i {
        margin-left: -30px;
        cursor: pointer;
    }

}