.-Nav {
    $-rs: '.-Nav';

    &-list {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &-link {
        flex-grow: 1;
        display: block;
        text-decoration: none;
        color: inherit;

        &:not(:any-link) {
            cursor: default;
        }

        &:any-link:hover {
            color: inherit;
            // opacity: 0.8;
        }

        &[data-state="current"] {
            // opacity: 0.8;
        }
    }

    &-linkContents {
        display: flex;
    }

    &[data-variant="main"] {
        height: 100vh;
        width: in-range($-main-nav-expanded-min-width, $-main-nav-expanded-max-width);
        transition-duration: $-main-nav-transition;
        transition-property: width;

        #{$-rs}-header {
            width: in-range($-main-nav-expanded-min-width, $-main-nav-expanded-max-width);
            border-bottom: $-main-nav-header-border;
        }

        #{$-rs}-body {
            width: in-range($-main-nav-expanded-min-width, $-main-nav-expanded-max-width);
            transition-duration: $-main-nav-transition;
            transition-property: padding-left;
        }

        &>* {
            overflow: hidden;
            overflow-y: auto;
            transition-duration: $-main-nav-transition;
            transition-property: width;
            position: fixed;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            height: 100vh;
            border-right: 2px solid $-primary-color;
            width: in-range($-main-nav-expanded-min-width, $-main-nav-expanded-max-width);
        }

        #{$-rs}-logo {
            text-align: center;
            padding-bottom: -PxToRem($-main-nav-logo-padding);
            padding-top: -PxToRem($-main-nav-logo-padding);
            width: -PxToRem($-main-nav-logo-width);
            padding-right: -PxToRem(25px);
        }

        &[data-expanded="false"] {
            width: in-range($-main-nav-min-width, $-main-nav-max-width);

            &>* {
                width: in-range($-main-nav-min-width, $-main-nav-max-width);
            }

            #{$-rs}-icon {
                width: in-range($-main-nav-min-width, $-main-nav-max-width);
            }

            #{$-rs}-label {
                display: none;
            }
        }

        #{$-rs}-item[data-variant="heading"] {
            border-bottom: $-main-nav-heading-border;
            border-top: $-main-nav-heading-border;
            background: $-main-nav-heading-background;
        }

        #{$-rs}-icon {
            transition-duration: $-main-nav-transition;
            padding-top: -PxToRem($-main-nav-item-padding);
            padding-bottom: -PxToRem($-main-nav-item-padding);
            text-align: center;
            width: -PxToRem($-main-nav-icon-width);
        }

        #{$-rs}-label {
            padding-top: -PxToRem($-main-nav-item-padding);
            padding-bottom: -PxToRem($-main-nav-item-padding);
        }

        #{$-rs}-linkContents {
            display: block;
        }

        #{$-rs}-linkContent {
            display: block;
        }

        .active {
            #{$-rs}-linkContent {
                background: $-main-nav-active-background;
            }
        }

        #{$-rs}-linkContent:hover {
            background: $-main-nav-hover-background;
        }
    }
}