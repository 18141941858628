@import "custom";

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body {
    background-color: $-background-color;
    font-size: in-range($-base-font-size, $-base-font-size-md);
    color: $-text-color;
    font-family: $-primary-font-family;
}

.Toastify__toast {
    border-radius: 0px;
}