// Path
$-base-fonts-path: "../../../fonts/";
$-base-images-path: "../images/";


//---------------------------------------------------------
// Scale
//---------------------------------------------------------
$-step: 2;
$-scale: ('05': 1/($-step*3),
    '04': 1/($-step*2 + ($-step - 1)),
    '03': 1/($-step*2),
    '02': 1/($-step + ($-step - 1)),
    '01': 1/$-step,
    '1': 1,
    '2': $-step,
    '3': $-step + ($-step - 1),
    '4': $-step*2,
    '5': $-step*2 + ($-step - 1),
    '6': $-step*3,
    '7': $-step*3 + ($-step - 1),
    '8': $-step*4,
    '9': $-step*4 + ($-step - 1),
    '10': $-step*5,
    '11': $-step*5 + ($-step - 1),
    '12': $-step*6,
    '13': $-step*6 + ($-step - 1),
    '14': $-step*7,
    '15': $-step*7 + ($-step - 1));


//---------------------------------------------------------
// Fragment
//---------------------------------------------------------
$-fragment: ('1of1': 1,
    '1of2': 1/2,
    '1of3': 1/3,
    '1of4': 1/4,
    '1of5': 1/5,
    '1of6': 1/6,
    '1of7': 1/7,
    '1of8': 1/8,
);


// //---------------------------------------------------------
// // Color
// //---------------------------------------------------------

$-primary-color: #FDC52F;

$-dark-color: #333;
$-light-color: #fff;
$-text-color: $-dark-color;
$-line-color: #bbb;
$-table-even-color: #ddd;

$-background-color: #f7f7f7;

$-error-color: #E23E32;
$-success-color: #03B209;
$-info-color: #2B8AD2;


// //---------------------------------------------------------
// // General
// //---------------------------------------------------------
// // Typography [style, variant, weight, stretch, size, line-height, family]

// // Font Size
$-user-agent-font-size: 18px;
$-base-font-size: 14px;
$-base-font-size-md: 20px;

// Font Weight
$-thin-font-weight: 100;
$-extra-light-font-weight: 200;
$-light-font-weight: 300;
$-normal-font-weight: 400;
$-medium-font-weight: 500;
$-semi-bold-font-weight: 600;
$-bold-font-weight: 700;
$-extra-bold-font-weight: 800;
$-black-font-weight: 900;
$-extra-black-font-weight: 950;

// Font Family
$-font-face-prefix: '';
$-font-face-suffix: 'webfont';
$-font-face-filename-separator: '-';
$-primary-font-face: ('regular', $-normal-font-weight, normal),
('bold', $-bold-font-weight, normal);

$-primary-font-face-name: 'Roboto';

$-primary-font-family: $-primary-font-face-name,
Helvetica,
"Segoe UI",
"Arimo",
Arial,
"Noto Sans",
sans-serif,
"Apple Color Emoji",
"Segoe UI Emoji",
"Segoe UI Symbol",
"Noto Color Emoji",
"icomoon"
;

// //---------------------------------------------------------
// // Spacing
// //---------------------------------------------------------
$-v-spacing: ('sm': 5px,
    'md': 10px);

$-h-spacing: ('sm': 5px,
    'md': 10px);


// //---------------------------------------------------------
// // Grid
// //---------------------------------------------------------
$-grid-column-width: 90px;
$-grid-column-count: 12;
$-grid-gutter-width: map-get($-h-spacing, 'md');
$-grid-column-spacing: $-grid-gutter-width/2;


// //---------------------------------------------------------
// // Screen
// //---------------------------------------------------------
$-screen-xs-min: 0;
$-screen-sm-min: 576px;
$-screen-md-min: 768px;
$-screen-lg-min: 992px;
$-screen-xl-min: 1200px;
$-screen-xs-max: $-screen-sm-min - 1;
$-screen-sm-max: $-screen-md-min - 1;
$-screen-md-max: $-screen-lg-min - 1;
$-screen-lg-max: $-screen-xl-min - 1;

// 
// //---------------------------------------------------------
// // Container
// //---------------------------------------------------------
$-container-base-width: $-grid-column-width*$-grid-column-count+$-grid-gutter-width*($-grid-column-count - 1);
$-container-spacing: $-grid-gutter-width+$-grid-gutter-width/2;
$-container-spacing-md: $-container-spacing;
$-container-width: $-container-base-width+$-container-spacing-md*2;


// //---------------------------------------------------------
// // Button
// //---------------------------------------------------------

$-button-padding: map-get($-h-spacing, 'sm')*2;
$-button-background-color: $-light-color;
$-button-hover-background-color: $-primary-color;
$-button-border: 2px solid $-primary-color;
$-button-transition: 0.15s ease-in-out;
$-button-font-weight: $-bold-font-weight;
$-button-radius: 0;
$-button-color: $-dark-color;

// //---------------------------------------------------------
// // Input
// //---------------------------------------------------------

$-input-padding: map-get($-h-spacing, 'sm')*3;
$-input-background-color: $-light-color;
$-input-border: 1px solid $-dark-color;
$-error-input-border: 1px solid $-error-color;
$-input-focus-border: 1px solid $-primary-color;
$-error-input-focus-border: 1px solid $-error-color;
$-input-hover-border: 1px solid $-primary-color;
$-input-focus-box-shadow: 0 0 0 1px $-primary-color;
$-error-input-focus-box-shadow: 0 0 0 1px $-error-color;
$-input-transition: 0.15s ease-in-out;
$-input-font-weight: $-bold-font-weight;
$-input-radius: 0;


// //---------------------------------------------------------
// // Nav
// //---------------------------------------------------------
$-main-nav-logo-padding: 5px;
$-main-nav-logo-width: 110px;

$-main-nav-expanded-min-width: 250px;
$-main-nav-expanded-max-width: 300px;
$-main-nav-min-width: 50px;
$-main-nav-max-width: 60px;
$-main-nav-transition: .3s;
$-main-nav-border: 2px solid $-primary-color;
$-main-nav-header-border: 2px solid $-primary-color;

$-main-nav-item-padding: 5px;
$-main-nav-icon-width: 40px;
$-main-nav-heading-border: 2px solid $-primary-color;
$-main-nav-heading-background: rgba($-primary-color, 1);

$-main-nav-active-background: rgba($-primary-color, 0.4);
$-main-nav-hover-background: rgba($-primary-color, 0.2);

