.-boxLayout {
    $-spacing: -PxToRem(map-get($-h-spacing, 'sm'));
    $-spacing-md: -PxToRem(map-get($-h-spacing, 'md'));
    $-relative-spacing: -PxToEm(map-get($-h-spacing, 'sm'), $-base-font-size);
    $-relative-spacing-md: -PxToEm(map-get($-h-spacing, 'md'), $-base-font-size-md);

    display: flex;
    flex-direction: column;

    &[data-justify="center"] {
        align-items: center;
    }

    &[data-align="center"] {
        justify-content: center;
    }

    @each $name,
    $value in $-scale {
        &[data-space="#{$name}"] {
            padding: $-relative-spacing*$value;

            @include breakpoint(md) {
                padding: $-relative-spacing-md*$value;
            }
        }

        &[data-space="#{$name}"][data-relativeSpace="false"] {
            padding: $-spacing*$value;

            @include breakpoint(md) {
                padding: $-spacing-md*$value;
            }
        }
    }

    @include breakpoint(md) {
        @each $name,
        $value in $-scale {
            &[data-space-md="#{$name}"] {
                padding: $-relative-spacing*$value;

                @include breakpoint(md) {
                    padding: $-relative-spacing-md*$value;
                }
            }
        }
    }
}