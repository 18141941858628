.-coverLayout {
    $-spacing: -PxToRem(map-get($-h-spacing, 'sm') / 2);
    $-spacing-md: -PxToRem(map-get($-h-spacing, 'md') / 2);

    display: flex;
    flex-direction: column;
    min-height: 100vh;

    > *:only-child {
        margin-top: auto;
        margin-bottom: auto;
    }

    @each $name, $value in $-scale {
        &[data-space="#{$name}"] {
            padding: $-spacing*$value;

            > *:not(:only-child) {
                margin-top: $-spacing*$value;
                margin-bottom: $-spacing*$value;
            }

            @include breakpoint(md) {
                padding: $-spacing-md*$value;

                > *:not(:only-child) {
                    margin-top: $-spacing-md*$value;
                    margin-bottom: $-spacing-md*$value;
                }
            }
        }
    }
}
