.-Burger {
    $-rs: '.-Burger';

    width: in-range($-main-nav-min-width, $-main-nav-max-width);
    display: flex;
    align-items: center;
    justify-content: center;
    height: in-range($-main-nav-min-width, $-main-nav-max-width);
    transition: .3s ease;
    position: relative;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    &-icon {
        display: block;
        position: relative;
        width: -PxToRem(24px);
        height: -PxToRem(3px);
        background-color: currentColor;
        transition: .3s;
        color: $-text-color;

        &::before,
        &::after {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: currentColor;
            transition: .3s;
        }

        &:before {
            transform: translateY(-PxToRem(-8px));
        }

        &:after {
            transform: translateY(-PxToRem(8px));
        }
    }

    &[data-expanded="true"] {
        #{$-rs}-icon {
            background-color: transparent;

            &:before {
                transform: rotate(45deg);
                height: -PxToRem(2px);
            }

            &:after {
                transform: rotate(-45deg);
                height: -PxToRem(2px);
            }
        }
    }
}