.-centerLayout {
    $-spacing: -PxToRem(map-get($-h-spacing, 'sm')*2);
    $-spacing-md: -PxToRem($-grid-gutter-width);
    $-column-width: -PxToRem($-grid-column-width);

    display: flex;
    flex-direction: column;
    align-items: center;

    &[data-justify-md="false"] {
        @include breakpoint(md) {
            align-items: flex-start;
        }
    }

    &[data-contentsWidth]>* {
        max-width: 100%;
    }

    @for $i from 1 through $-grid-column-count {
        &[data-colspan="#{$i}"]>* {
            width: $-column-width*$i + $-spacing*($i - 1);

            @include breakpoint(md) {
                width: $-column-width*$i + $-spacing-md*($i - 1);
            }
        }
    }
}