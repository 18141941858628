.-Container {
    $-rs: '.-Container';
    $-relative-spacing: -PxToEm(map-get($-h-spacing, 'sm') / 2, $-base-font-size);
    $-relative-spacing-md: -PxToEm(map-get($-h-spacing, 'md') / 2, $-base-font-size-md);
    $-relative-column-width: -PxToEm($-grid-column-width, $-base-font-size-md);

    display: block;
    max-width: 95%;
    padding-right: -PxToRem($-container-spacing);
    padding-left: -PxToRem($-container-spacing);
    padding-top: -PxToRem($-container-spacing);
    margin-right: auto;
    margin-left: auto;

    @include breakpoint(md) {
        padding-right: -PxToRem($-container-spacing-md);
        padding-left: -PxToRem($-container-spacing-md);

    }

    
    @for $i from 1 through $-grid-column-count {
        &[data-colspan="#{$i}"] {
            max-width: $-relative-column-width*$i + $-relative-spacing*2*($i - 1);

            @include breakpoint(md) {
                max-width: $-relative-column-width*$i + $-relative-spacing-md*2*($i - 1);
            }
        }
    }
}
