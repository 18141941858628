.-Table {
    width: 100%;
    border-collapse: collapse;

    & th{
        background-color: $-dark-color;
        color: $-light-color;
    }

    & th, td {
        text-align: left;
        padding: 5px;
    }

    & tr:nth-child(even) {
        background-color: $-table-even-color;
    }
}