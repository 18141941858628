%button {
    border: $-button-border;
    background-color: $-button-background-color;
    padding: -PxToRem($-button-padding) -PxToRem($-button-padding);
    border-radius: $-button-radius;
    width: 100%;
    font-weight: $-button-font-weight;
    font-size: inherit;
    transition: $-button-transition;
    font-family: $-primary-font-family;
    white-space: nowrap;
    text-decoration: none;
    color: $-button-color;


    &:focus,
    &:hover {
        outline: none;
        color: $-button-color;
        background-color: $-button-hover-background-color;
        cursor: pointer;
    }

    &[data-type="icon"] {
        width: in-range(40px, 45px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}